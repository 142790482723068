import { useState, useEffect } from "react";
import { ethers } from "ethers";
import ErrorMessagePopup from '../components/popups/ErrorMessagePopup';
import SuccessMessagePopup from '../components/popups/SuccessMessagePopup';

const WGSA_TOKEN_ADDRESS = "0x6f5c47c85E55ef4E0d17c4d221C79d0e7a0A4650";
const EPEPE_TOKEN_ADDRESS = "0x2528b12bF1b1B01A01F01b3AE9fBeFC19e444Dff";
const TOKEN_SWAP_CONTRACT_ADDRESS = "0x4363b5A9100786cFe8c7d60BC6BfEd7547Cc3Ef2";

const TOKEN_SWAP_ABI = [
    "function swapWgsaToEpepe(uint256 wgsaAmount) external",
    "function swapEpepeToWgsa(uint256 epepeAmount) external",
];

const EPEPE_ABI = [
    "function approve(address spender, uint256 amount) external returns (bool)",
    "function balanceOf(address account) external view returns (uint256)",
];

const WGSA_ABI = [
    "function approve(address spender, uint256 amount) external returns (bool)",
    "function balanceOf(address account) external view returns (uint256)",
];

const TokenSwap = () => {
    const [epepeAmount, setEpepeAmount] = useState(0);
    const [wgsaAmount, setWgsaAmount] = useState(0);
    const [isApproved, setIsApproved] = useState(false);
    const [wgsaBalance, setWgsaBalance] = useState(0);
    const [epepeBalance, setEpepeBalance] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (errorMessage) {
            const timer = setTimeout(() => setErrorMessage(""), 3000);
            return () => clearTimeout(timer);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => setSuccessMessage(""), 3000);
            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    const fetchBalances = async () => {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            const wgsaToken = new ethers.Contract(WGSA_TOKEN_ADDRESS, WGSA_ABI, signer);
            const wgsaBalance = await wgsaToken.balanceOf(await signer.getAddress());
            setWgsaBalance(parseFloat(ethers.utils.formatUnits(wgsaBalance, 18)).toFixed(2));

            const epepeToken = new ethers.Contract(EPEPE_TOKEN_ADDRESS, EPEPE_ABI, signer);
            const epepeBalance = await epepeToken.balanceOf(await signer.getAddress());
            setEpepeBalance(parseFloat(ethers.utils.formatUnits(epepeBalance, 18)).toFixed(2));
        }
    };

    useEffect(() => {
        fetchBalances();
    }, []);

    const handleEpepeAmountChange = (event) => {
        const amount = event.target.value;
        setEpepeAmount(amount);

        if (amount > 0) {
            const calculatedWgsa = amount / 1000; // 1 WGSA = 1000 EPEPE
            setWgsaAmount(calculatedWgsa.toFixed(2));
        }

        setIsApproved(false);
    };

    const handleApprove = async () => {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const epepeToken = new ethers.Contract(EPEPE_TOKEN_ADDRESS, EPEPE_ABI, signer);

            setIsLoading(true);

            try {
                const approveTx = await epepeToken.approve(TOKEN_SWAP_CONTRACT_ADDRESS, ethers.utils.parseUnits(epepeAmount.toString(), 18));
                await approveTx.wait();
                setIsApproved(true);
                setSuccessMessage("Successful EPEPE approval.");
            } catch (err) {
                console.error("Error on approve EPEPE:", err);
                setErrorMessage("Error on approve EPEPE.");
            } finally {
                setIsLoading(false);
            }
        } else {
            setErrorMessage("Please, install MetaMask.");
        }
    };

    const handleSwap = async () => {
        if (!isApproved) {
            setErrorMessage("Approve Tokens First.");
            return;
        }

        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const swapContract = new ethers.Contract(TOKEN_SWAP_CONTRACT_ADDRESS, TOKEN_SWAP_ABI, signer);

            const adjustedAmount = Math.floor(epepeAmount); // Asegurarse de que sea un múltiplo de 1000

            if (adjustedAmount <= 0) {
                setErrorMessage("The set quantity is not valid. Try another quantity.");
                return;
            }

            setIsLoading(true);

            try {
                const swapTx = await swapContract.swapEpepeToWgsa(ethers.utils.parseUnits(adjustedAmount.toString(), 18));
                await swapTx.wait();
                setSuccessMessage(`Successful Swap: ${adjustedAmount} EPEPE for WGSA.`);
                await fetchBalances();
            } catch (err) {
                console.error("Error on Swap:", err);
                setErrorMessage("Error on Swap. Try Again.");
            } finally {
                setIsLoading(false);
            }
        } else {
            setErrorMessage("Please, install MetaMask.");
        }
    };

    const handleMaxClick = () => {
        setEpepeAmount(epepeBalance);

        if (epepeBalance > 0) {
            const calculatedWgsa = epepeBalance / 1000; // 1 WGSA = 1000 EPEPE
            setWgsaAmount(calculatedWgsa.toFixed(2));
        }
    };

    return (
        <section id="token-swap" className="nft2-collections">
            <div className="container-fluid">
                <div className="text-center">
                    <div className="title-style-2 text-center d-flex align-items-center justify-content-center">
                        <h2 className="mb-0">EPEPE/WGSA</h2>
                    </div>
                    <p>Token Swap</p>
                </div>
                <div className="row g-3 justify-content-center">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="nft2-collection-column">
                                <img
                                    src="assets/img/home2/epepe-token-cover.png"
                                    className="img-fluid rounded-top position-relative"
                                    alt="cover"
                                />
                                <div className="profile-wrapper">
                                    <img
                                        src="assets/img/home2/epepe-token.png"
                                        className="img-fluid"
                                        alt="profile"
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "5px" }}>
                                    <div>
                                        <p style={{ fontSize: "12px", margin: 0 }}><img src="/assets/img/home2/Token.png" style={{ width: "20px" }} /> Balance: {epepeBalance}</p>
                                    </div>
                                    <button
                                        style={{
                                            fontSize: '12px',
                                            color: 'white',
                                            cursor: 'pointer',
                                            background: 'none',
                                            border: 'none',
                                        }}
                                        onClick={handleMaxClick}
                                    >
                                        Set Max
                                    </button>
                                </div>
                                <div>
                                    <input
                                        id="epepeAmount"
                                        type="number"
                                        value={epepeAmount}
                                        onChange={handleEpepeAmountChange}
                                        min="1"
                                        step="any"
                                        style={{ border: "1px solid grey", backgroundColor: "transparent", borderRadius: "5px", color: "white", textAlign: "center", width: "100%" }}
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "5px" }}>
                                    <div style={{ paddingTop: "40px" }}>
                                        <p style={{ fontSize: "12px", margin: 0 }}><img src="/assets/img/home2/WGSAV2.png" style={{ width: "20px" }} /> Balance: {wgsaBalance}</p>
                                    </div>
                                    <div style={{ paddingTop: "40px" }}>
                                        <p
                                            style={{
                                                marginBottom: "0px",
                                                fontSize: '12px',
                                                color: 'white',
                                            }}
                                        >
                                            You receive
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <input
                                        value={wgsaAmount > 0 ? wgsaAmount : "0"}
                                        readOnly
                                        style={{ border: "1px solid grey", backgroundColor: "transparent", borderRadius: "5px", color: "white", textAlign: "center", width: "100%" }}
                                    />
                                </div>
                                {successMessage && <SuccessMessagePopup message={successMessage} onClose={() => setSuccessMessage('')} />}
                                {errorMessage && <ErrorMessagePopup message={errorMessage} onClose={() => setErrorMessage('')} />}
                                <div style={{ marginTop: "30px" }}>
                                    {!isApproved ? (
                                        <button style={{ border: "1px solid grey", color: "white", width: "100px", borderRadius: "5px" }} onClick={isLoading ? null : handleApprove} disabled={isLoading}>{isLoading ? "Loading..." : "Approve"}</button>
                                    ) : (
                                        <button style={{ border: "1px solid grey", color: "white", width: "100px", borderRadius: "5px" }} onClick={isLoading ? null : handleSwap} disabled={isLoading}>{isLoading ? "Loading..." : "Swap"}</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx>{`
                    input[type="number"]::-webkit-outer-spin-button,
                    input[type="number"]::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input[type="number"] {
                        -moz-appearance: textfield;
                    }
                `}</style>
            </div>
        </section>
    );
};

export default TokenSwap;
